<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-row class="mt-5">
          <v-text-field
              v-model="form.merchant_product_id"
              label="(Optional) Merchant Custom product ID"
              hint="Merchant's custom product ID, usually associated with internal number."
            ></v-text-field>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { between, required } from '@core/utils/validation';
import { } from '@mdi/js';
import {
  ref
} from '@vue/composition-api';

export default {
  components: {
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    nature: {
      type: String,
      default: 'tangible',
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const nature = props.resource ? props.resource.nature : props.nature

    const getNatureInfo = () => {
      if (['service', 'activity'].includes(nature)) {
        return {
          service: {
            min_duration_minute: 1,
            max_duration_minute: 1,
            min_participant: 1,
            max_participant: 1,
          },
        }
      }

      return {
        [nature]: {},
      }
    }

    const noRule = ['tangible', 'plan'].includes(nature)
    // const defaultRequired = computed(() => productStore.state.participantRules.find(r => r.mandatory && r.fields.birthday === 'optional')?.id)
    // const defaultNotRequired = computed(() => productStore.state.participantRules.find(r => !r.mandatory)?.id)
    // const defaultRuleId = noRule ? defaultNotRequired : defaultRequired.value

    const boolOrTrue = item => ([true, false].includes(item) ? item : true)

    const initialForm = {
      merchant_product_id: props.resource ? props.resource.merchant_product_id : '',
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      console.log('valid?', formElem.value.validate())
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      between,
      required,
      noRule,
    }
  },
}
</script>

<style lang="scss">
.content-editors {
  .ck-content {
    height: 500px; // so that it does not overflow the stepper
  }
}
</style>