import mstore from '@/modules/merchant/store'
import store from '@/store'

/**
 * Get current merchant's id
 */
const merchantId = () => mstore.state.current.id

/**
 * Return errors array message in array
 */
const extractErrors = err => {
  console.log('err', err)
  let errors = []

  const {
    response: { data },
  } = err
  console.log('err message', data)
  if (data.data) {
    errors = data.data.map(d => d.message)
  } else {
    errors = [data.message]
  }

  return errors
}

const notifyErrors = err => {
  console.log('err', err)
  const errors = extractErrors(err)
  const error = errors.join('<br><br>')
  store.dispatch('app/addNotifications', [{ title: 'Request Error.', content: error }])

  return Promise.reject(err)
}

const confirm = ({ title, content, persistent = true }) =>
  new Promise((resolve, reject) => {
    store.dispatch('app/addNotifications', [
      {
        title,
        content,
        resolve,
        reject,
        persistent,
        resolveText: 'Confirm',
        rejectText: 'No',
      },
    ])
  })

const notifySuccess = ({ content, color, timeout }) => {
  store.dispatch('app/setSnackbar', { content, color, timeout: timeout === undefined ? 2000 : timeout })
}

/**
 * Break down the table options (sort, per page)
 * and assign query to API request
 */
const withOptions = (tableOptions, query = {}) => {
  // filter null or empty key in query
  Object.keys(query).forEach(key => {
    if (query[key] === null || query[key] === '') {
      delete query[key]
    }
  })

  const finalQuery = { ...query }

  tableOptions.sortBy.forEach((sortBy, index) => {
    const sorting = tableOptions.sortDesc[index] === true ? 'desc' : 'asc'

    finalQuery[`order_by[${sortBy}]`] = sorting
  })

  finalQuery.take = tableOptions.itemsPerPage
  finalQuery.page = tableOptions.page

  return finalQuery
}

const translatable = (field, name) => {
  const placeholder = window.location.href.includes('localhost')
    ? {
        en: 'test dummy input',
        tc: 'test dummy input',
        sc: 'test dummy input',
      }
    : {
        en: '',
        tc: '',
        sc: '',
      }
  if (!field || !name || !field[name]) {
    return placeholder
  }

  return {
    en: field[name].en,
    tc: field[name].tc,
    sc: field[name].sc,
  }
}

const sortAlphabetTitle = (a, b) => {
  if (a.title < b.title) {
    return -1
  }
  if (a.title > b.title) {
    return 1
  }

  return 0
}

/**
 * Remove tag from html text, to display in options (like product policy option)
 */
const stripTags = html => {
  const div = document.createElement('div')
  div.innerHTML = html

  return div.textContent || div.innerText || ''
}

const slugify = text =>
  text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[-]+/g, '-')
    .replace(/[^\w-]+/g, '')

const alphaDash = text => text.replace(/[^a-zA-Z0-9-]/g, '')

export { alphaDash, confirm, extractErrors, merchantId, notifyErrors, notifySuccess, slugify, sortAlphabetTitle, stripTags, translatable, withOptions }
